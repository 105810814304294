@use 'src/constants/colors' as colors;
@use 'src/constants/fonts' as fonts;
@use 'src/constants/deviceDimensions' as dimensions;

.education-tab-panel {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .education-panel-section {
        display: flex;
        gap: 40px;

        @media (max-width: dimensions.$narrow-viewport-min-width) {
            flex-direction: column;
            gap: 10px;
            align-items: center;
        }

        .education-panel-school-details {
            display: flex;
            flex-direction: column;
            gap: 20px;
            flex-grow: 1;

            h1 {
                font-size: fonts.$page-section-title-font-size;
                border-bottom: 1px solid colors.$white;
                padding-bottom: 8px;
                margin: 0;

                @media (max-width: dimensions.$narrow-viewport-min-width) {
                    font-size: fonts.$mini-page-section-title-font-size;
                }
            }

            .education-panel-school-description {
                background-color: colors.$panel-tab-panel-section-background;
                border-radius: 8px;
                padding: 20px;
                display: flex;
                flex-direction: column;

                span {
                    margin-bottom: 10px;
                }

                dl {
                    margin: 0;

                    dd {
                        padding-left: 2rem;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
}
