@use 'src/constants/colors' as colors;
@use 'src/constants/deviceDimensions' as dimensions;
@use 'src/constants/fonts' as fonts;

.game-dev-page-section {
    .game-development-description-container {
        display: flex;
        align-items: center;
        gap: 20px;

        @media (max-width: dimensions.$narrow-viewport-min-width) {
            flex-direction: column;

            .image-link-container {
                order: 2;
            }
        }

        .description {
            h1 {
                margin-top: 0;
            }
        }
    }

    .favorite-games-container {
        margin-top: 20px;

        h1 {
            font-size: fonts.$page-section-title-font-size;
            margin: 0 0 20px 0;
            padding-bottom: 4px;
            border-bottom: 1px solid colors.$white;

            @media (max-width: dimensions.$narrow-viewport-min-width) {
                font-size: fonts.$mini-page-section-title-font-size;
            }
        }

        .description-list-container {
            border-radius: 8px;
            padding: 20px;
            background-color: colors.$panel-paragraph-background;
            font-size: fonts.$paragraph-font-size;

            @media (max-width: dimensions.$narrow-viewport-min-width) {
                font-size: fonts.$mini-paragraph-font-size;
            }

            dt {
                width: fit-content;
                a {
                    color: colors.$inline-link;
                    text-decoration: none;
                    outline: none;
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    transition: color 0.5s;

                    &:hover,
                    &:focus {
                        color: colors.$inline-link-selected;
                    }

                    &:active {
                        color: colors.$inline-link-active;
                    }

                    svg {
                        font-size: 0.8em;
                    }
                }
            }

            dd {
                padding-left: 1em;
            }
        }
    }
}
