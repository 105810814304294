@use 'src/constants/deviceDimensions' as dimensions;

$page-section-title-font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
$page-section-title-font-size: 40px;
$mini-page-section-title-font-size: 28px;
$paragraph-font-size: 20px;
$mini-paragraph-font-size: 16px;

$nav-title-font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
$nav-title-font-size: 40px;
$nav-item-font-size: 20px;

$hamburger-nav-title-font-size: 30px;

$tab-font-size: 20px;
