@use 'src/constants/colors' as colors;
@use 'src/constants/fonts' as fonts;
@use 'src/constants/deviceDimensions' as dimensions;

.work-tab-panel {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .work-panel-section {
        display: flex;
        gap: 40px;

        @media (max-width: dimensions.$narrow-viewport-min-width) {
            flex-direction: column;
            gap: 10px;
            align-items: center;
        }

        .work-panel-company-details {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            gap: 10px;

            h1 {
                font-size: fonts.$page-section-title-font-size;
                border-bottom: 1px solid colors.$white;
                padding-bottom: 8px;
                margin: 0;

                @media (max-width: dimensions.$narrow-viewport-min-width) {
                    font-size: fonts.$mini-page-section-title-font-size;
                }
            }

            p {
                margin: 0;
            }

            .work-panel-company-role-details {
                background-color: colors.$panel-tab-panel-section-background;
                border-radius: 8px;
                padding: 20px;
                display: flex;
                flex-direction: column;

                ul {
                    margin: 0;
                }
            }
        }
    }
}
