@use 'src/constants/colors' as colors;
@use 'src/constants/fonts' as fonts;
@use 'src/constants/zIndex' as zIndex;
@use 'src/constants/deviceDimensions' as dimensions;

.hamburger-nav {
    background-color: colors.$panel-background;
    z-index: zIndex.$nav-bar;
    position: sticky;
    top: 0;
    box-shadow: 0 2px 5px colors.$black;

    button {
        position: absolute;
        top: 0;
        height: dimensions.$hamburger-nav-height;
        width: dimensions.$hamburger-nav-height;
        background-color: colors.$panel-background;
        border: none;
        cursor: pointer;

        &:hover,
        &:focus {
            background-color: colors.$button-selected;
        }

        &:active {
            background-color: colors.$button-active;
        }

        svg {
            color: colors.$white;
            font-size: fonts.$hamburger-nav-title-font-size;
        }
    }

    .nav-title {
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        font-size: fonts.$hamburger-nav-title-font-size;
        font-family: fonts.$nav-title-font-family;
        color: colors.$white;

        @media (max-width: dimensions.$narrow-viewport-min-width) {
            svg {
                display: none;
            }
        }

        .nav-title-names {
            display: flex;
            gap: 4px;
            flex-shrink: 0;

            h1 {
                margin: 0;
                font-weight: normal;
                font-size: fonts.$hamburger-nav-title-font-size;
            }
        }
    }
}

.offcanvas {
    .offcanvas-header {
        background-color: colors.$panel-background;
        border-bottom: 1px solid colors.$button-active;

        button {
            color: colors.$white;
        }
    }

    .offcanvas-body {
        background-color: colors.$panel-background;
        padding: 0;

        .navigation-item {
            box-sizing: border-box;
            padding: 20px 0 20px 20px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            color: colors.$white;
            text-decoration: none;
            outline: none;
            font-size: fonts.$nav-item-font-size;
            gap: 8px;
            transition: background-color 0.5s;
            border-bottom: 1px solid colors.$button-active;

            &:hover,
            &:focus {
                background-color: colors.$button-selected;
            }

            &:active {
                background-color: colors.$button-active;
            }
        }
    }
}
