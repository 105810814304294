@use 'src/constants/colors' as colors;
@use 'src/constants/deviceDimensions' as dimensions;
@use 'src/constants/fonts' as fonts;

.dnd-page-section {
    .dnd-description-container {
        display: flex;
        gap: 20px;

        @media (max-width: dimensions.$narrow-viewport-min-width) {
            flex-direction: column;
        }

        .initiative-roller-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 8px;

            & > span {
                font-size: fonts.$paragraph-font-size;
            }
        }

        .description {
            h1 {
                margin-top: 0;
            }
        }
    }

    .dnd-links {
        margin-top: 20px;
        display: flex;
        justify-content: space-evenly;
        gap: 20px;

        @media (max-width: dimensions.$narrow-viewport-min-width) {
            flex-direction: column;
        }
    }
}
