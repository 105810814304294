$button-default: #8ea7df;
$button-active: #425fa3;
$button-selected: #4f6db4;

$website-background: #e8e8e8;

$panel-background: #6e89cb;
$panel-title-background: #4f6db4;
$panel-paragraph-background: #4f6db4;
$panel-tab-panel-background: #8ea7df;
$panel-tab-panel-section-background: #4f6db4;

$inline-link: #d6fdff;
$inline-link-selected: #86b9bc;
$inline-link-active: #689598;

$black: #000000;
$white: #ffffff;
