@use 'src/constants/colors' as colors;
@use 'src/constants/fonts' as fonts;
@use 'src/constants/zIndex' as zIndex;
@use 'src/constants/deviceDimensions' as dimensions;

.page-section + .page-section {
    margin-top: 40px;
}

.page-section {
    background-color: colors.$panel-background;
    box-shadow: 1px 5px 10px colors.$black;
    max-width: 1400px;
    width: 100%;
    z-index: zIndex.$page-section;
    opacity: 0.95;

    @media (max-width: dimensions.$narrow-viewport-min-width) {
        min-width: fit-content;
    }

    h1.page-section-title {
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: fonts.$page-section-title-font-size;
        padding: 20px;
        font-weight: bold;
        font-family: fonts.$page-section-title-font-family;
        background-color: colors.$panel-title-background;
        color: colors.$white;

        @media (max-width: dimensions.$narrow-viewport-min-width) {
            font-size: fonts.$mini-page-section-title-font-size;
        }
    }

    .page-section-content {
        color: colors.$white;
        padding: 40px;

        @media (max-width: dimensions.$narrow-viewport-min-width) {
            padding: 10px;
        }
    }
}

.page-section-scroll-spy-marker {
    position: relative;
    top: calc(-1 * dimensions.$main-page-vertical-padding);

    @media (max-width: dimensions.$desktop-viewport-min-width) {
        top: calc((-1 * dimensions.$hamburger-nav-height) - dimensions.$main-page-vertical-padding);
    }

    @media (max-width: dimensions.$narrow-viewport-min-width) {
        top: calc((-1 * dimensions.$hamburger-nav-height) - dimensions.$mini-main-page-vertical-padding);
    }
}
