@use 'src/constants/fonts' as fonts;
@use 'src/constants/colors' as colors;
@use 'src/constants/deviceDimensions' as dimensions;

.skills-tab-panel {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .skills-panel-section {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .skills-panel-section-title {
            line-height: unset;
            display: flex;
            gap: 8px;
            align-items: flex-start;
            justify-content: flex-start;
            font-size: fonts.$page-section-title-font-size;
            border-bottom: 1px solid colors.$white;

            @media (max-width: dimensions.$narrow-viewport-min-width) {
                font-size: fonts.$mini-page-section-title-font-size;
            }

            h1 {
                font-size: fonts.$page-section-title-font-size;

                @media (max-width: dimensions.$narrow-viewport-min-width) {
                    font-size: fonts.$mini-page-section-title-font-size;
                }
            }
        }

        .skills-panel-section-list-container {
            background-color: colors.$panel-tab-panel-section-background;
            border-radius: 8px;
            padding: 20px;

            .skills-panel-section-list {
                margin: 0;
            }
        }
    }

    .skills-panel-columns {
        display: flex;
        justify-content: space-evenly;

        .skills-panel-column {
            display: flex;
            flex-direction: column;
            gap: 20px;
        }
    }
}
