@use 'src/constants/colors' as colors;

a.inline-link {
    color: colors.$inline-link;
    text-decoration: none;
    transition: color 0.5s;
    outline: none;

    &:hover,
    &:focus {
        color: colors.$inline-link-selected;
    }

    &:active {
        color: colors.$inline-link-active;
    }
}
