@use 'src/constants/colors' as colors;
@use 'src/constants/fonts' as fonts;
@use 'src/constants/zIndex' as zIndex;
@use 'src/constants/deviceDimensions' as dimensions;

.desktop-nav {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    background-color: colors.$panel-background;
    align-items: center;
    width: 300px;
    position: fixed;
    top: 0;
    height: 100vh;
    box-shadow: 0 5px 5px colors.$black;
    z-index: zIndex.$nav-bar;

    /* CSS specific to iOS devices */
    @supports (-webkit-touch-callout: none) {
        height: -webkit-fill-available;
    }

    .nav-title {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        margin-bottom: 20px;
        color: colors.$white;
        font-size: fonts.$nav-title-font-size;

        h1 {
            font-family: fonts.$nav-title-font-family;
            font-size: fonts.$nav-title-font-size;
            margin: 0;
            font-weight: normal;
        }
    }

    .navigation-item {
        box-sizing: border-box;
        padding: 20px 0 20px 20px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: colors.$white;
        text-decoration: none;
        outline: none;
        font-size: fonts.$nav-item-font-size;
        gap: 8px;
        transition: background-color 0.5s;

        &:hover,
        &:focus,
        &.scrollspy-active {
            background-color: colors.$button-selected;
        }

        &:active {
            background-color: colors.$button-active;
        }
    }
}
