@use 'src/constants/colors' as colors;
@use 'src/constants/deviceDimensions' as dimensions;

.welcome-page-section {
    display: flex;
    flex-direction: column;

    .quote-container {
        display: flex;
        align-items: center;
        gap: 20px;
        justify-content: center;

        @media (max-width: dimensions.$narrow-viewport-min-width) {
            flex-direction: column;
        }

        .profile-pic-border {
            width: 200px;
            height: 200px;
            min-width: 200px;
            position: relative;
            overflow: hidden;
            border-radius: 50%;
            border: 10px solid colors.$white;

            img {
                display: inline;
                margin: 0 auto;
                height: 100%;
                width: auto;
            }
        }

        .quote {
            font-size: 26px;
            line-height: 1.4;
            font-family: Georgia, serif;
            margin: 0;
            background-color: #3d599c;
            padding: 1em;
            border-radius: 20px;

            @media (max-width: dimensions.$narrow-viewport-min-width) {
                font-size: 20px;
            }

            figcaption {
                margin: 1em;
            }
        }
    }

    .welcome-page-section-icon-buttons {
        display: flex;
        gap: 8px;
        margin-top: 20px;
    }
}
